import React from 'react'
import { connect } from 'react-redux'
import Menu from 'antd/es/menu'
import Dropdown from 'antd/es/dropdown'
import Avatar from 'antd/es/avatar'
import 'antd/es/avatar/style'
import 'antd/es/menu/style'
import 'antd/es/dropdown/style'

import { FormattedMessage } from 'react-intl'
import ModalCambiarPassword from '../ChangePassword/index'
import styles from './style.module.scss'

@connect(({ user }) => ({ user }))
class ProfileMenu extends React.Component {
  state = {
    // count: 7,
  }

  // componentDidMount() {
  //   console.log(this.props.profile)
  // }

  logout = e => {
    e.preventDefault()
    const { dispatch } = this.props
    dispatch({
      type: 'LOGOUT',
    })
  }

  // addCount = () => {
  //   let { count } = this.state
  //   count += 1
  //   // this.setState({
  //   //   count,
  //   // })
  // }

  cambiarPassword = e => {
    e.preventDefault()
    this.refModalCambiarPassword.open()
  }

  render() {
    const { user } = this.props
    // const { count } = this.state
    const menu = (
      <Menu selectable={false}>
        <Menu.Item>
          <strong>{this.props.profile.razon_social || 'Anonymous'}</strong>
          <div>
            <strong className="mr-1">Ruc:</strong>
            {this.props.profile.ruc_empresa}
          </div>
          <div>
            <strong>Perfil:</strong>
            {this.props.profile.perfil}
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <a href="#" onClick={e => this.cambiarPassword(e)}>
            <i className={`${styles.menuIcon} icmn-key`} />
            <FormattedMessage id="topBar.profileMenu.password" />
          </a>
        </Menu.Item>
        <Menu.Item>
          <a href="#" onClick={e => this.logout(e)}>
            <i className={`${styles.menuIcon} icmn-exit`} />
            <FormattedMessage id="topBar.profileMenu.logout" />
          </a>
        </Menu.Item>
      </Menu>
    )
    return (
      <span>
        <span className={styles.usernameStyle}>{this.props.profile.username}</span>
        <Dropdown overlay={menu} trigger={['click']}>
          <Avatar className={styles.avatar} shape="square" size="large" icon="user" />
        </Dropdown>
        <ModalCambiarPassword
          ref2={ref2 => {
            this.refModalCambiarPassword = ref2
          }}
        />
      </span>
    )
  }
}

export default connect(state => {
  return {
    profile: state.user.profile,
  }
})(ProfileMenu)
