import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import Loadable from 'react-loadable'
import { connect } from 'react-redux'
import Loader from 'components/LayoutComponents/Loader'
import IndexLayout from 'layouts'
import NotFoundPage from 'pages/404'

const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  })

const routes = [
  // System Pages
  {
    path: '/user/login',
    component: loadable(() => import('pages/user/login')),
    exact: true,
  },
  {
    path: '/user/forgot',
    component: loadable(() => import('pages/user/forgot')),
    exact: true,
  },

  // Dashboards
  {
    path: '/dashboard',
    component: loadable(() => import('pages/dashboard')),
  },
  // Establecimientos
  {
    path: '/establecimientos/crear',
    component: loadable(() => import('pages/establecimientos/crear')),
    exact: true,
  },
  {
    path: '/ruta',
    component: loadable(() => import('pages/ruta')),
    exact: true,
  },
  {
    path: '/dp/servicios',
    component: loadable(() => import('pages/DP/servicios/')),
    exact: true,
  },
  {
    path: '/ruta/list',
    component: loadable(() => import('pages/ruta/list')),
    exact: true,
  },
  {
    path: '/establecimientos/list',
    component: loadable(() => import('pages/establecimientos/establecimientos-list')),
    exact: true,
  },
  {
    path: '/establecimientos/editar',
    component: loadable(() => import('pages/establecimientos/editar')),
    exact: true,
  },
  {
    path: '/establecimientos/empresa',
    component: loadable(() => import('pages/establecimientos/empresa/')),
    exact: true,
  },
  // Transportistas
  {
    path: '/transportistas/list',
    component: loadable(() => import('pages/transportistas/list')),
    exact: true,
  },
  {
    path: '/transportistas/asociacion',
    component: loadable(() => import('pages/transportistas/asociacion')),
    exact: true,
  },
  {
    path: '/transportistas/preferidos',
    component: loadable(() => import('pages/transportistas/preferidos/')),
    exact: true,
  },
  {
    path: '/transportistas/categoria',
    component: loadable(() => import('pages/categoria/list')),
    exact: true,
  },
  // Tarifa
  {
    path: '/tarifa/ciudad',
    component: loadable(() => import('pages/tarifa/ciudad')),
    exact: true,
  },
  {
    path: '/tarifa/clienteCiudad',
    component: loadable(() => import('pages/tarifa/clienteCiudad')),
    exact: true,
  },
  {
    path: '/tarifa/planta',
    component: loadable(() => import('pages/tarifa/planta')),
    exact: true,
  },
  // Servicio
  {
    path: '/servicio/transporte',
    component: loadable(() => import('pages/servicio/transporte')),
    exact: true,
  },
  {
    path: '/servicio/transporteCliente',
    component: loadable(() => import('pages/servicio/transporteCliente')),
    exact: true,
  },
  {
    path: '/reservas/monitoreo',
    component: loadable(() => import('pages/reservas/monitoreo')),
    exact: true,
  },
  {
    path: '/reservas/list',
    component: loadable(() => import('pages/reservas/list')),
    exact: true,
  },
  {
    path: '/reservas/solicitudes',
    component: loadable(() => import('pages/reservas/solicitudes')),
    exact: true,
  },
  {
    path: '/reservas/update_containers',
    component: loadable(() => import('pages/reservas/contenedores/actualizarContenedores')),
    exact: true,
  },
  {
    path: '/reservas/create_import',
    component: loadable(() => import('pages/reservas/reservar/import')),
    exact: true,
  },
  {
    path: '/documentos/consultar',
    component: loadable(() => import('pages/reservas/reservar/consultar')),
    exact: true,
  },
  {
    path: '/reservas/create_export',
    component: loadable(() => import('pages/reservas/reservar/export')),
    exact: true,
  },
  {
    path: '/reservas/create_vacio',
    component: loadable(() => import('pages/reservas/reservar/vacio')),
    exact: true,
  },
  {
    path: '/reservas/create_vacio_export',
    component: loadable(() => import('pages/reservas/reservar/vacio_export')),
    exact: true,
  },
  {
    path: '/reservas/create_vacio_export_match',
    component: loadable(() => import('pages/reservas/reservar/reservas_export_vacio')),
    exact: true,
  },
  {
    path: '/reservas/adm_vacio',
    component: loadable(() => import('pages/reservas/reservar/adm_vacio')),
    exact: true,
  },
  // creditos
  {
    path: '/creditos/list',
    component: loadable(() => import('pages/creditos/list')),
    exact: true,
  },
  // rubros
  {
    path: '/rubros/list',
    component: loadable(() => import('pages/rubros/list')),
    exact: true,
  },
  {
    path: '/rubros/iva',
    component: loadable(() => import('pages/rubros/iva')),
    exact: true,
  },
  // ordenes
  {
    path: '/ordenes/list',
    component: loadable(() => import('pages/ordenes/list')),
    exact: true,
  },
  {
    path: '/tarifa/cliente/',
    component: loadable(() => import('pages/tarifa/cliente/')),
    exact: true,
  },
  {
    path: '/tarifa/fms/clientes',
    component: loadable(() => import('pages/tarifa/fms/clientes')),
    exact: true,
  },
  {
    path: '/tarifa/fms/transportistas',
    component: loadable(() => import('pages/tarifa/fms/transportistas')),
    exact: true,
  },
  {
    path: '/reglas/list/',
    component: loadable(() => import('pages/reglas/list/')),
    exact: true,
  },
  {
    path: '/agentes/',
    component: loadable(() => import('pages/agentes/')),
    exact: true,
  },
  // {
  //   path: 'reservas/solicitudes_match/',
  //   component: loadable(() => import('pages/reservas/reservar/vacio/')),
  //   exact: true,
  // },
  {
    path: '/reservas/solicitudes_match',
    component: loadable(() => import('pages/reservas/solicitudes_match/')),
    exact: true,
  },
  {
    path: '/reservas/solicitudes_match_transportista',
    component: loadable(() => import('pages/reservas/solicitudes_match/')),
    exact: true,
  },
]

class Router extends React.Component {
  render() {
    const { history } = this.props
    return (
      <ConnectedRouter history={history}>
        <IndexLayout>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
            {routes.map(route => (
              <Route
                path={route.path}
                component={route.component}
                key={route.path}
                exact={route.exact}
              />
            ))}
            <Route component={NotFoundPage} />
          </Switch>
        </IndexLayout>
      </ConnectedRouter>
    )
  }
}

export default connect(state => {
  return {
    isLoggedIn: state.user.profile != null,
  }
})(Router)
