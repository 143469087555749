import 'rc-drawer/assets/index.css'
import React from 'react'
import firebase from 'firebase/app'
import DrawerMenu from 'rc-drawer'
import { connect } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'

import Button from 'antd/es/button'
import notification from 'antd/es/notification'
import 'antd/es/button/style'
import 'antd/es/notification/style'

import ReactInterval from 'react-interval'
import MenuLeft from './MenuLeft'
import MenuTop from './MenuTop'
import FirebaseStartComponent from '../../../firebase'

require('firebase/messaging')

const mapStateToProps = ({ settings }) => ({
  isMenuTop: settings.isMenuTop,
  isMobileMenuOpen: settings.isMobileMenuOpen,
  isMobileView: settings.isMobileView,
  isLightTheme: settings.isLightTheme,
  intervalEnable: true,
})

const openNotification = (title, body, callBack) => {
  const key = `open${Date.now()}`
  const btn = (
    <Button
      type="primary"
      size="small"
      onClick={() => {
        notification.close(key)
        callBack()
        console.log('CLICK NOTIF')
      }}
    >
      Revisar ahora
    </Button>
  )
  notification.config({
    placement: 'bottomRight',
  })
  notification['info']({
    message: title,
    description: body,
    duration: 7,
    btn,
    key,
  })
}

@withRouter
@connect(mapStateToProps)
class AppMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      redirectToList: false,
      path: null,
    }
  }

  componentDidMount() {
    this.setUpFirebaseListener()
  }

  getNotifications = () => {
    const { isMenuTop, menu } = this.props

    if (menu.REFRESHING_MENU) {
      if (isMenuTop) {
        this.menuTop.getNotificaciones()
      } else {
        this.menuLeft.getNotificaciones()
      }
      menu.REFRESHING_MENU = false
    }
  }

  setUpFirebaseListener = async () => {
    if (!firebase.apps.length) {
      firebase.initializeApp(FirebaseStartComponent.config)
    }
    try {
      const messaging = firebase.messaging()
      messaging.onMessage(payload => {
        const { menu } = this.props
        menu.REFRESHING_MENU = true
        openNotification(payload.notification.title, payload.notification.body, () => {
          this.setPathToRedirect('/reservas/solicitudes')
        })
      })
    } catch (error) {
      console.log(error)
    }
  }

  toggleOpen = () => {
    const { dispatch, isMobileMenuOpen } = this.props
    document
      .querySelector('#root')
      .setAttribute(
        'style',
        !isMobileMenuOpen ? 'overflow: hidden; width: 100%; height: 100%;' : '',
      )
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMobileMenuOpen',
        value: !isMobileMenuOpen,
      },
    })
  }

  refMenuTop = ref => {
    this.menuTop = ref
  }

  refMenuLeft = ref => {
    this.menuLeft = ref
  }

  setPathToRedirect = path => {
    console.log('REDIRECT ')
    this.setState({ path: path, redirectToList: true })
  }

  renderRedirect = () => {
    const { path } = this.state
    if (this.state.redirectToList) {
      this.state.redirectToList = false
      return <Redirect to={{ pathname: path }} />
    }
  }

  render() {
    const { isMenuTop, isMobileMenuOpen, isMobileView, isLightTheme } = this.props
    const BootstrappedMenu = () => {
      if (isMobileView) {
        return (
          <DrawerMenu
            getContainer={null}
            level={null}
            open={isMobileMenuOpen}
            onMaskClick={this.toggleOpen}
            onHandleClick={this.toggleOpen}
            className={isLightTheme ? 'drawer-light' : ''}
          >
            <MenuLeft ref2={this.refMenuLeft} />

            <ReactInterval
              timeout={1000}
              enabled={this.props.intervalEnable}
              callback={() => {
                this.getNotifications()
              }}
            />
            {this.renderRedirect()}
          </DrawerMenu>
        )
      }
      if (isMenuTop) {
        return (
          <div>
            <MenuTop ref2={this.refMenuTop} />
            <ReactInterval
              timeout={1000}
              enabled={this.props.intervalEnable}
              callback={() => {
                this.getNotifications()
              }}
            />
            {this.renderRedirect()}
          </div>
        )
      }
      return (
        <div>
          <MenuLeft ref2={this.refMenuLeft} />
          <ReactInterval
            timeout={1000}
            enabled={this.props.intervalEnable}
            callback={() => {
              this.getNotifications()
            }}
          />
          {this.renderRedirect()}
        </div>
      )
    }

    return BootstrappedMenu()
  }
}

export default connect(state => {
  return {
    menu: state.menu,
  }
})(AppMenu)
