import React from 'react'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Icon from 'antd/lib/icon'

import styles from './style.module.scss'
import logo from '../../../assets/logo_dp_cortado.png'

const Footer = () => (
  <div className={styles.footer}>
    <div className={styles.inner}>
      <div className={styles.bottom}>
        <div className={styles.copyright}>
          <div className={styles.containerFooter}>
            <Row gutter={20} type="flex" justify="center">
              <Col md={12} sm={12} xs={24}>
                <img src={logo} rel="noopener noreferrer" alt="FMS" />
                <span>
                  © 2019 DP World Posorja | FMS
                  <br />
                  Todos los derechos reservados. v1.6.3
                </span>
              </Col>
              <Col md={12} sm={12} xs={24}>
                <Row gutter={20} type="flex" justify="space-around" align="middle">
                  {/* <Col xs={12}>
                    <strong>COMERCIAL</strong>
                    <br />
                    <Icon type="phone" /> +593 42-598-080
                    <br />
                    <Icon type="mail" /> fmscomercial@soltrans.com.ec
                  </Col> */}
                  <Col xs={24} md={24} sm={24}>
                    <strong>CONTACTO SOLTRANS - FMS</strong>
                    <br />
                    <Icon type="phone" /> +593-969455247
                    <br />
                    <Icon type="mail" style={{ marginRight: 5 }} />
                    <a href="mailto:operaciones.soltrans@dpworld.com">
                      operaciones.soltrans@dpworld.com
                    </a>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Footer
