/* eslint-disable func-names */
import axios from 'axios'
// const urlAPIAPP = 'http://localhost:5002/'
// const baseURL = 'http://localhost:5001'

const urlAPIAPP = 'https://apps.fmsdp.com/'
const baseURL = 'https://api.fmsdp.com'

export default class RestClientObj {
  static instanceAxios = axios.create({
    baseURL,
  })

  // static instanceAxios_prod = axios.create({
  //   baseURL: 'https://api.fmsdp.com',
  // })

  static setInterceptor = callback => {
    RestClientObj.instanceAxios.interceptors.response.use(
      function(response) {
        return response
      },
      function(error) {
        if (!error.response) {
          callback()
        }

        if (!error.response && error.message) {
          throw Error(error.message)
        }

        if (error.response.status === 403) {
          // throw Error(error.response.data.message)
          callback()
        } else {
          // if(!error.response.data.message.mensaje)
          //   throw Error(error.response.data.message.mensaje)
          // else
          if (error.response.data) {
            if (error.response.data.mensaje) throw Error(error.response.data.mensaje)

            throw Error(error.response.data.message)
          }
        }
      },
    )
  }

  static setTokenToAxio = token => {
    RestClientObj.instanceAxios.defaults.headers.common.Authorization = token
    // RestClientObj.instanceAxios_prod.defaults.headers.common.Authorization = token
  }
  static cleartokenAxio = () => {
    delete RestClientObj.instanceAxios.defaults.headers.common.Authorization
    RestClientObj.instanceAxios.defaults.headers.common.Authorization = undefined
  }

  static setAuthorization = () => {
    // if (!RestClientObj.instanceAxios.defaults.headers.common.Authorization)
    //   RestClientObj.instanceAxios.defaults.headers.common.Authorization = localStorage.getItem(
    //     'token',
    //   )
  }

  static perfilMenu = userData => {
    return RestClientObj.instanceAxios.post('/users/getPerfilMenu', userData)
  }

  static cambiarPassword = params => {
    return RestClientObj.instanceAxios
      .post('/users/reset_password', params)
      .then(response => response.data)
    // .catch(error => {
    //   console.log(error)
    //   return error.response
    // })
  }

  static getNotificaciones = () => {
    return RestClientObj.instanceAxios
      .get('/users/get_notifications')
      .then(response => response.data)
      .catch(error => error.response)
  }

  static userLogin = userData => {
    this.cleartokenAxio()
    return RestClientObj.instanceAxios.post('/users/login', userData)
  }

  static getPerfilUser = userData => {
    return RestClientObj.instanceAxios.post('/users/getPerfil', userData)
  }

  static getCorreosClientesDP = request => {
    return RestClientObj.instanceAxios
      .post('/users/get_correos_cliente_dp', request)
      .then(response => response.data)
  }

  static getCorreosRecuperarClientesDP = request => {
    return RestClientObj.instanceAxios
      .post('/users/get_correos_cliente_dp_recuperar', request)
      .then(response => response.data)
  }

  static resetPasswordCorreosDP = request => {
    return RestClientObj.instanceAxios
      .post('/users/new_password_correo_notificacion', request)
      .then(response => response.data)
  }

  static getCorreosClientesDPAdmin = request => {
    return RestClientObj.instanceAxios
      .post('/migracionDP/get_correos_cliente_dp', request)
      .then(response => {
        const { data, status } = response
        return { ...data, status }
      })
  }

  static getConductoresTransportistaAdmin = request => {
    return RestClientObj.instanceAxios
      .post('/migracionDP/getTransportista', request)
      .then(response => {
        const { data, status } = response
        return { ...data, status }
      })
  }

  static getCamionesTransportistaAdmin = request => {
    return RestClientObj.instanceAxios
      .post('/migracionDP/getInformacionTransportista', request)
      .then(response => {
        const { data, status } = response
        return { ...data, status }
      })
  }

  static getDatosDAEAdmin = request => {
    return RestClientObj.instanceAxios.post('/migracionDP/getDae', request).then(response => {
      const { data, status } = response
      return { ...data, status }
    })
  }

  static getReestriccionesClienteDP = request => {
    return RestClientObj.instanceAxios
      .post('/users/get_restricciones_cliente_dp', request)
      .then(response => response.data)
  }

  static getProvincia = filter => {
    return RestClientObj.instanceAxios
      .post('/establecimiento/getProvincia', filter)
      .then(response => response.data)
  }

  static getCiudad = filter => {
    return RestClientObj.instanceAxios
      .post('/establecimiento/getCiudad', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static iniciarHito = filter => {
    const tmp = { ...RestClientObj.instanceAxios }
    const axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: tmp.defaults.headers.common.Authorization,
      },
    }
    return axios
      .post(urlAPIAPP + 'reservas/iniciar_hito_manual', filter, axiosConfig)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static consultarTarifarioAplicado = filter => {
    const tmp = { ...RestClientObj.instanceAxios }
    const axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: tmp.defaults.headers.common.Authorization,
      },
    }
    return axios
      .post(urlAPIAPP + 'reservas/consultarTarifarioAplicado', filter, axiosConfig)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static finalizarTodosHitosReserva = filter => {
    const tmp = { ...RestClientObj.instanceAxios }
    const axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: tmp.defaults.headers.common.Authorization,
      },
    }

    return axios
      .post(urlAPIAPP + 'reservas/finalizar_hitos_reserva', filter, axiosConfig)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static getCiudadNombre = filter => {
    return RestClientObj.instanceAxios
      .post('/establecimiento/getCiudadNombre', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static getTipoContenedor = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/getTipoContenedores', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static getReservasExportBooking = request => {
    return RestClientObj.instanceAxios
      .post('/reservas/reservas_export_booking', request)
      .then(response => response.data)
  }

  static actualizarDatosReserva = request => {
    return RestClientObj.instanceAxios
      .post('/reservas/actualizar_reserva_fms', request)
      .then(response => response.data)
  }

  static actualizarContenedoresExportVacios = request => {
    return RestClientObj.instanceAxios
      .post('/reservas/actualizar_contenedor_reservas_exp', request)
      .then(response => response.data)
  }

  static buscarSolicitudMatch = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/buscarSolicitudMatch', filter)
      .then(response => response.data)
  }

  static crearMatchManual = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/crearMatchManual', filter)
      .then(response => response.data)
  }

  static crearMatchReservaDummy = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/crearMatchReservaDummy', filter)
      .then(response => response.data)
  }

  static actualizarDatosEmpresa = filter => {
    return RestClientObj.instanceAxios
      .post('/establecimiento/actualizarDatosEmpresa', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static getTipoMovimiento = filter => {
    return RestClientObj.instanceAxios
      .get('/admin/ruta_waypoint/getTipoMovimiento', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static buscarEmpresasClientes = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/transportista/get_clientes', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static crearCitaDP = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/crearCitaDP', filter)
      .then(response => response.data)
  }

  static getEstadoCitaDP = request => {
    return RestClientObj.instanceAxios
      .post('/reservas/get_estado_cita_dp', request)
      .then(response => response.data)
  }

  static getEstadoCitaDPAdmin = request => {
    return RestClientObj.instanceAxios
      .post('/migracionDP/getEstadoCitaDP', request)
      .then(response => {
        const { data, status } = response
        return { ...data, status }
      })
  }

  static cancelarCitaDPAdmin = request => {
    return RestClientObj.instanceAxios
      .post('/migracionDP/eliminarCitaDP', request)
      .then(response => {
        const { data, status } = response
        return { ...data, status }
      })
      .catch(error => {
        console.log(error)
      })
  }

  static getResumenViajes = request => {
    return RestClientObj.instanceAxios
      .post('/reservas/getResumenViajes', request)
      .then(response => response.data)
  }

  static editarCitaDP = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/editarCitaDP', filter)
      .then(response => response.data)
  }

  static eliminarCitaDP = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/eliminarCitaDP', filter)
      .then(response => response.data)
  }

  static getListadoTransportistas = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/transportista/listado_transportistas_fms', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static agregarClienteTransportistaFacturacion = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/transportista/agregarClienteTransportistaFacturacion', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static buscarListadoClientes = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/transportista/buscarListadoClientes', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static cambiarEstadoClienteTransportista = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/transportista/cambiarEstadoClienteTransportista', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static actualizarDatosTransportista = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/transportista/actualizarDatosTransportista', filter)
      .then(response => response.data)
  }

  static consultaCamionesAsociados = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/transportista/consultaCamionesAsociados', filter)
      .then(response => response.data)
  }

  static actualizarEstadoAsociaciones = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/transportista/actualizarEstadoAsociaciones', filter)
      .then(response => response.data)
  }

  static actualizarRecursosTransportista = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/transportista/actualizarRecursosTransportista', filter)
      .then(response => response.data)
  }

  static consultaMisAsocianesTransportista = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/transportista/consultaMisAsocianesTransportista', filter)
      .then(response => response.data)
  }

  static consultaAsociadoTransportista = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/transportista/consultaAsociadoTransportista', filter)
      .then(response => response.data)
  }

  static quitarAsignacionTransportista = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/transportista/quitarAsignacionTransportista', filter)
      .then(response => response.data)
  }

  static getTransportistasList = filter => {
    return RestClientObj.instanceAxios.post('/admin/transportista/', filter)
  }

  static eliminarAdjuntoSolicitud = filter => {
    return RestClientObj.instanceAxios
      .post('/categoria/eliminarAdjuntoSolicitud/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static getTransportista = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/transportista/getTransportista/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static getEmpresas = filter => {
    return RestClientObj.instanceAxios
      .post('/users/get_users/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static buscarReservasPorBooking = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/buscarReservasPorBooking/', filter)
      .then(response => response.data)
  }

  static generarOrdenesPorBooking = filter => {
    return RestClientObj.instanceAxios
      .post('/ordenes/generarOrdenesPorBooking/', filter)
      .then(response => response.data)
  }

  static addTransportistaPreferido = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/transportista/addTransportistaPreferido/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static getListaTransportistaPreferido = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/transportista/getListaTransportistaFavoritos/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static actualizarEstadoTransportista = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/transportista/actualizarEstadoTransportista/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static getEstablecimientos = filter => {
    return RestClientObj.instanceAxios
      .post('/establecimiento/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static buscarRelacionReservaCamion = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/buscarRelacionReservaCamion/', filter)
      .then(response => response.data)
  }

  static crearRelacionReservasCamion = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/crearRelacionReservasCamion/', filter)
      .then(response => response.data)
  }

  static eliminarRelacionReservasCamion = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/eliminarRelacionReservasCamion/', filter)
      .then(response => response.data)
  }

  static eliminarAsignacionTransportistaFacturacion = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/eliminarAsignacionTransportistaFacturacion/', filter)
      .then(response => response.data)
  }

  static eliminarAsignacionTransportistaCita = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/eliminarAsignacionTransportistaCita/', filter)
      .then(response => response.data)
  }

  /**
   * Obtiene un Establecimiento por ID o Tag
   * @param {id_establecimiento: number, tag: string - "DP" } filter
   */
  static getEstablecimiento = filter => {
    return RestClientObj.instanceAxios
      .post('/establecimiento/getEstablecimiento/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  /**
   * Obtiene una Ruta por IDs de Establecimientos de Origen y Destino
   * @param id_establecimiento_origen: number
   * @param id_establecimiento_destino: number
   */
  static getRutaByEstablecimientos = (id_est_orig, id_est_dest) => {
    return RestClientObj.instanceAxios
      .post('/establecimiento/getRutaByEstablecimientos/', {
        id_establecimiento_origen: id_est_orig,
        id_establecimiento_destino: id_est_dest,
      })
      .then(response => response.data)
  }

  static getEstablecimientosMonitoring = filter => {
    return RestClientObj.instanceAxios
      .post('/establecimiento/getEstablecimientosMonitoring/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static getListaNavieras = () => {
    return RestClientObj.instanceAxios.get('/vacio/').then(response => response.data)
  }

  static getListaVacios = filter => {
    return RestClientObj.instanceAxios.post('/vacio/list/', filter).then(response => response.data)
  }

  static agregarVacio = filter => {
    return RestClientObj.instanceAxios
      .post('/vacio/addVacio', filter)
      .then(response => response.data)
  }

  static agregarHito = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/addHito/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static eliminarHito = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/eliminarHito/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  /**
   * Guarda una reserva de Import
   * @param documento: Utils.Documento
   * @param reserva: Utils.Reserva
   * @param hitos Utils.Reserva.getHitos() - Arreglo de hitos
   */
  static guardarReservaImport = (documento, reserva, hitos) => {
    return RestClientObj.instanceAxios
      .post('/reservas/crear_reserva_import/', {
        documento: documento,
        reserva: reserva,
        hitos: hitos,
      })
      .then(response => response.data)
  }

  static getDocumentoByReserva = id_reserva => {
    return RestClientObj.instanceAxios
      .get('/reservas/get_documento_reserva/' + id_reserva)
      .then(response => response.data)
  }

  /**
   * Creación de documento
   * @param {string} params.documento
   * @param {number} params.cantidad
   */
  static crearDocumento = params => {
    return RestClientObj.instanceAxios
      .post('/reservas/crear_documento/', params)
      .then(response => response.data)
  }

  static consultar_disponibilidad_dp = ({ documento, reserva }) => {
    return RestClientObj.instanceAxios
      .post('/reservas/dp_consultar_disponibilidad/', {
        documento: documento,
        reserva: reserva,
      })
      .then(response => response.data)
  }

  static testHorarioDisponible = (documento, reserva) => {
    return RestClientObj.instanceAxios
      .post('/reservas/dp_consultar_disponibilidad_hack/', {
        documento: documento,
        reserva: reserva,
      })
      .then(response => response.data)
  }

  /**
   * Guarda una reserva de Export
   * @param documento: Utils.Documento
   * @param reserva: Utils.Reserva
   * @param hitos Utils.Reserva.getHitos() - Arreglo de hitos
   */
  static guardarReservaExport = (documento, reserva, hitos) => {
    return RestClientObj.instanceAxios
      .post('/reservas/crear_reserva_export/', {
        documento: documento,
        reserva: reserva,
        hitos: hitos,
      })
      .then(response => response.data)
  }

  static actualizar_hitos_reserva = params => {
    return RestClientObj.instanceAxios
      .post('/reservas/actualizar_hitos_reserva', params)
      .then(response => response.data)
  }

  /**
   * Guarda una reserva de Import
   * @param {number} id_transportista ID del transportista seleccionado
   * @param {numbre} id_reserva  ID de reserva
   * @param {string} fecha_reserva_inicio  -- format "YYYY-MM-DD HH:mm:ss"
   * @param {string} fecha_reserva_fin  -- format "YYYY-MM-DD HH:mm:ss"
   */
  static seleccionTransportistaImport = (
    id_transportista,
    id_reserva,
    fecha_reserva_inicio,
    fecha_reserva_fin,
  ) => {
    return RestClientObj.instanceAxios
      .post('/reservas/seleccion_transportista/', {
        id_transportista: id_transportista,
        id_reserva: id_reserva,
        fecha_reserva_inicio: fecha_reserva_inicio,
        fecha_reserva_fin: fecha_reserva_fin,
      })
      .then(response => response.data)
  }

  /**
   * Actualiza el ID de la reserva DP. Válido solo para ADMINISTRADOR
   * @param {numbre} id_reserva  ID de reserva
   * @param {number} reserva_DP ID del transportista seleccionado
   */
  static actualizarReservaDP = (id_reserva, reserva_DP) => {
    return RestClientObj.instanceAxios
      .post('/reservas/set_reserva_dp/', {
        id_reserva: id_reserva,
        reserva_DP: reserva_DP,
      })
      .then(response => response.data)
  }

  /**
   * Actualiza requerimientos especiales de una reserva: Válido para: CLIENTE, AGENTE
   * @param {numbre} id_reserva  ID de reserva
   * @param {number} requerimientos ID del transportista seleccionado
   */
  static actualizarRequerimientos = (id_reserva, requerimientos) => {
    return RestClientObj.instanceAxios
      .post('/reservas/set_requerimientos_especiales/', {
        id_reserva: id_reserva,
        requerimientos: requerimientos,
      })
      .then(response => response.data)
  }

  /**
   * Guarda una reserva de Import
   * @param {string} desde - format - "YYYY-MM-DD HH:mm:ss"
   * @param {string} hasta - format - "YYYY-MM-DD HH:mm:ss"
   * @param {number} tiempo_prudencial - (minutos) tiempo que se considera antes y después para considerar un camión disponible
   */
  static getTransportistasDisponiblesImport = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/disponibilidad_transportistas_import/', filter)
      .then(response => response.data)
  }

  /**
   * Cancelar reserva en DP
   * @param reserva:
   */
  static cancelarReserva = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/cancelar_cita_dp/', filter)
      .then(response => response.data)
  }

  /**
   * Verifica si el cli o trans tiene deudas o no
   * @param ruc - Ruc del cli o trans
   */
  static verificacionCliente = (ruc = null) => {
    return RestClientObj.instanceAxios
      .post('/reservas/verificacion_cliente/', { ruc: ruc })
      .then(response => response.data)
  }

  static listaReservas = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/listaReservas/', filter)
      .then(response => response.data)
      .catch(error => error)
  }

  static getEstados = filter => {
    return RestClientObj.instanceAxios
      .get('/reservas/estados/')
      .then(response => response.data)
      .catch(error => error.response)
  }

  static cambiarEstadoReserva = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/cambiarEstadoReserva/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  /**
   * Obtiene todos los equipos disponibles
   */
  static getEquipos = () => {
    return RestClientObj.instanceAxios
      .get('/reservas/get_equipos/')
      .then(response => response.data)
      .catch(error => error.response)
  }
  /**
   * Actualiza los equipos de una reserva
   */
  static updateEquiposReservas = (id_equipos, id_reserva) => {
    return RestClientObj.instanceAxios
      .post('/reservas/update_equipos_reserva/', { id_equipos: id_equipos, id_reserva: id_reserva })
      .then(response => response.data)
      .catch(error => error.response)
  }

  static getListaCiudades = filter => {
    return RestClientObj.instanceAxios
      .get('/ciudad/lista/')
      .then(response => response.data)
      .catch(error => error.response)
  }

  static getTarifaCiudades = filter => {
    return RestClientObj.instanceAxios
      .post('/ciudad/lista_ciudades/', filter)
      .then(response => response.data)
  }

  static ordenarHitos = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/ordenarHitos/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static deleteEstablecimiento = filter => {
    return RestClientObj.instanceAxios.post('/establecimiento/deleteEstablecimiento', filter)
  }

  static getMarkerEstablecimientos = filter => {
    return RestClientObj.instanceAxios
      .post('/establecimiento/getMarkerEstablecimiento/', filter)
      .then(response => response.data)
  }

  static getPoligono = filter => {
    return RestClientObj.instanceAxios
      .post('/establecimiento/getPoligono/', filter)
      .then(response => response.data)
  }

  static addEstablecimiento = filter => {
    return RestClientObj.instanceAxios
      .post('/establecimiento/addEstablecimiento', filter)
      .then(response => response.data)
  }

  static editEstablecimiento = filter => {
    return RestClientObj.instanceAxios
      .post('/establecimiento/editEstablecimiento', filter)
      .then(response => response.data)
  }

  static getTipoEstablecimiento = () => {
    return RestClientObj.instanceAxios
      .get('/establecimiento/getTipoEstablecimiento/')
      .then(response => response.data)
  }

  static getMapBounds = filter => {
    return RestClientObj.instanceAxios
      .post('/establecimiento/get_bounds/', filter)
      .then(response => response.data)
  }

  static getEstablecimientoByCiudad = filter => {
    return RestClientObj.instanceAxios.post('/establecimiento/getListaEstablecimiento/', filter)
  }

  static getCoordenadaByEstablecimiento = filter => {
    return RestClientObj.instanceAxios.post(
      '/establecimiento/getCoordenadaByEstablecimiento/',
      filter,
    )
  }

  static getEstablecimientoPuerto = filter => {
    return RestClientObj.instanceAxios.post('/establecimiento/getEstablecimientosPuerto/', filter)
  }

  static getEstablecimientos2 = filter => {
    return RestClientObj.instanceAxios.post('/establecimiento/getEstablecimientos/', filter)
  }

  static getEstablecimientosByAdmin = filter => {
    return RestClientObj.instanceAxios.post('/establecimiento/getEstablecimientosByAdmin/', filter)
  }

  static getEdosActivos = () => {
    return RestClientObj.instanceAxios.post('/documentos/getEdosActivos/')
  }

  static getDepositosEdos = () => {
    return RestClientObj.instanceAxios
      .post('/documentos/getDepositosEdos/')
      .then(response => response.data)
  }

  static createEdoActivo = params => {
    return RestClientObj.instanceAxios.post('/documentos/createEdoActivo/', params)
  }

  static deleteEdoActivo = params => {
    return RestClientObj.instanceAxios.post('/documentos/deleteEdoActivo/', params)
  }

  static createBookingActivo = params => {
    return RestClientObj.instanceAxios
      .post('/documentos/createBookingActivo/', params)
      .then(response => response.data)
  }

  static getBookingsActivos = () => {
    return RestClientObj.instanceAxios.post('/documentos/getBookingsActivos/')
  }

  static deleteBookingActivo = params => {
    return RestClientObj.instanceAxios
      .post('/documentos/deleteBookingActivo/', params)
      .then(response => response.data)
  }

  static getEstablecimientoPorCiudad = filter => {
    return RestClientObj.instanceAxios
      .post('/establecimiento/getEstablecimientosPorCiudad/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static getEstablecimientoByRucRazonSocial = filter => {
    return RestClientObj.instanceAxios
      .post('/establecimiento/getEstablecimientoByRucRazonSocial/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static getEstablecimientoByEmpresa = filter => {
    return RestClientObj.instanceAxios
      .post('/establecimiento/getEstablecimientoByEmpresa/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static getDatosEmpresa = filter => {
    return RestClientObj.instanceAxios
      .post('/establecimiento/getDatosEmpresas/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static listaOrdenPagoDetalleTipo = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/orden_pago_detalle_tipo/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static generarReporteExcel = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/generarReporteExcel/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static crearSolicitudMatch = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/crearSolicitudMatch/', filter)
      .then(response => response.data)
  }

  static agregarOrdenPagoDetalleTipo = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/agregar_orden_pago_detalle_tipo/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static eliminarOrdenPagoDetalleTipo = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/eliminar_orden_pago_detalle_tipo/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static actualizarOrdenPagoDetalleTipo = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/actualizar_orden_pago_detalle_tipo/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static actualizarValoresOrden = filter => {
    return RestClientObj.instanceAxios
      .post('/ordenes/actualizarValoresOrden', filter)
      .then(response => response.data)
  }

  static listaOrdenesPago = filter => {
    return RestClientObj.instanceAxios
      .post('/ordenes/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static actualizarOrdenCancelado = filter => {
    return RestClientObj.instanceAxios
      .post('/ordenes/actualizarOrdenCancelado/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static nuevaOrdenCliente = filter => {
    return RestClientObj.instanceAxios
      .post('/ordenes/nuevaOrdenCliente/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static getDetalleOrden = filter => {
    return RestClientObj.instanceAxios
      .post('/ordenes/getDetalleOrden', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static getClienteOrdenes = filter => {
    return RestClientObj.instanceAxios
      .post('/ordenes/getClienteOrdenes', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static registrarOrdenFactura = filter => {
    return RestClientObj.instanceAxios
      .post('/ordenes/registrarOrdenFactura', filter)
      .then(response => response.data)
  }

  static guardarRuta = filter => {
    return RestClientObj.instanceAxios.post('/admin/ruta_waypoint/addRutaWaypoint/', filter)
  }

  static getWaypointSugeridos = filter => {
    return RestClientObj.instanceAxios.post('/admin/ruta_waypoint/getWaypoint/', filter)
  }

  static getRutaByCiudad = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/ruta_waypoint/getRutaByCiudad/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static deleteRutaWaypoint = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/ruta_waypoint/deleteRuta/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static updateRutaWaypoint = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/ruta_waypoint/updateRuta/', filter)
      .then(response => response.data)
  }

  static getListaCamiones = filter => {
    return RestClientObj.instanceAxios.post('/camion/', filter)
  }

  static getListaEjes = filter => {
    return RestClientObj.instanceAxios
      .get('/camion/getListaEjes', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static actualizarInfoCamion = filter => {
    return RestClientObj.instanceAxios
      .post('/camion/actualizarInfoCamion', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static getListaChoferes = filter => {
    return RestClientObj.instanceAxios.post('/chofer/', filter)
  }

  static getChofer = filter => {
    return RestClientObj.instanceAxios.post('/chofer/getChofer', filter)
  }

  static quitarAsignacionConductor = filter => {
    return RestClientObj.instanceAxios
      .post('/chofer/quitarAsignacionConductor', filter)
      .then(response => response.data)
  }

  static getEstadoRutasChofer = filter => {
    return RestClientObj.instanceAxios
      .post('/chofer/estadoRutasChofer', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static misChoferes = filter => {
    return RestClientObj.instanceAxios
      .post('/chofer/misChoferes', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static getCamionesPropiosAsociados = filter => {
    return RestClientObj.instanceAxios
      .post('/camion/getCamionesPropiosAsociados', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static getChoferesPropiosAsociados = filter => {
    return RestClientObj.instanceAxios
      .post('/chofer/getChoferesPropiosAsociados', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static deleteChofer = filter => {
    return RestClientObj.instanceAxios.post('/chofer/deleteChofer', filter)
  }

  static getCamion = filter => {
    return RestClientObj.instanceAxios.post('/camion/getCamion', filter)
  }

  static quitarAsignacionCamion = filter => {
    return RestClientObj.instanceAxios
      .post('/camion/quitarAsignacionCamion', filter)
      .then(response => response.data)
  }

  static deleteCamion = filter => {
    return RestClientObj.instanceAxios.post('/camion/deleteCamion', filter)
  }

  static eliminarTransportista = filter => {
    return RestClientObj.instanceAxios.post('/admin/transportista/deleteTransportista/', filter)
  }

  static getSignedUrl = filter => {
    return RestClientObj.instanceAxios.post('/categoria/adjunto_s3-url', filter)
  }

  static getListaTransportista = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/transportista/getListaTransportista/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static getListaTransportistaFavorito = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/transportista/getListadoTransportistaFavoritos/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static sincronizarTransportistaDP = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/transportista/sincronizarTransportistaDP/', filter)
      .then(response => response.data)
  }

  static getTarifasTransportista = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/transportista/tarifasTransportista/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  /**
   * Consulta los diferentes tipos de reportes de tarifas.
   * @param {string} tipo - Tipo de reporte
   */
  static reporteTarifas = tipo => {
    return RestClientObj.instanceAxios
      .post('/tarifas/reporte', { tipo: tipo })
      .then(response => response.data)
  }

  static lista_tarifas_clientes_vacios = params => {
    return RestClientObj.instanceAxios
      .post('/tarifas/tarifas_clientes_vacios', params)
      .then(response => response.data)
  }

  static lista_tarifas_transportistas_vacios = params => {
    return RestClientObj.instanceAxios
      .post('/tarifas/tarifas_transportistas_vacios', params)
      .then(response => response.data)
  }

  static lista_tarifas_transportistas_cargados = params => {
    return RestClientObj.instanceAxios
      .post('/tarifas/tarifas_transportistas_cargados', params)
      .then(response => response.data)
  }

  static tipos_empresas = () => {
    return RestClientObj.instanceAxios
      .post('/empresas/tipo_empresas')
      .then(response => response.data)
  }

  static guardarEmpresa = params => {
    return RestClientObj.instanceAxios
      .post('/empresas/nueva_empresa', params)
      .then(response => response.data)
  }

  static eliminar_tarifa_cliente_vacio = params => {
    return RestClientObj.instanceAxios
      .post('/tarifas/eliminar_tarifa_cliente_vacio', params)
      .then(response => response.data)
  }

  static eliminar_tarifa_transportistas_vacio = params => {
    return RestClientObj.instanceAxios
      .post('/tarifas/eliminar_tarifa_transportistas_vacio', params)
      .then(response => response.data)
  }

  static eliminar_tarifa_transportistas_cargados = params => {
    return RestClientObj.instanceAxios
      .post('/tarifas/eliminar_tarifa_transportistas_cargados', params)
      .then(response => response.data)
  }

  static lista_movimientos_tarifas = () => {
    return RestClientObj.instanceAxios
      .get('/tarifas/lista_movimientos_tarifas')
      .then(response => response.data)
  }

  static nueva_tarifa_cliente_vacio = params => {
    return RestClientObj.instanceAxios
      .post('/tarifas/nueva_tarifa_cliente_vacio', params)
      .then(response => response.data)
  }

  static nueva_tarifa_transportista_vacio = params => {
    return RestClientObj.instanceAxios
      .post('/tarifas/nueva_tarifa_transportista_vacio', params)
      .then(response => response.data)
  }

  static nueva_tarifa_transportista_cargado = params => {
    return RestClientObj.instanceAxios
      .post('/tarifas/nueva_tarifa_transportista_cargado', params)
      .then(response => response.data)
  }

  static actualizar_tarifa_cliente_vacio = params => {
    return RestClientObj.instanceAxios
      .post('/tarifas/actualizar_tarifa_cliente_vacio', params)
      .then(response => response.data)
  }

  static actualizar_tarifa_transportista_vacio = params => {
    return RestClientObj.instanceAxios
      .post('/tarifas/actualizar_tarifa_transportista_vacio', params)
      .then(response => response.data)
  }

  static actualizar_tarifa_transportista_cargado = params => {
    return RestClientObj.instanceAxios
      .post('/tarifas/actualizar_tarifa_transportista_cargado', params)
      .then(response => response.data)
  }

  static calcularCostoEstimadoTransportista = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/transportista/costoEstimadoTransportista/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static verDetalleCosto = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/transportista/getDetalleCosto/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static costoEstimadoListaTransportistaFavorito = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/transportista/costoEstimadoListaTransportistaFavorito/', filter)
      .then(response => response.data)
    // .catch(error => error.response)
  }

  static liberarTransportistaReserva = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/liberarReserva/', filter)
      .then(response => response.data)
  }
  // categoria

  static listaSolicitudesCategoriaPendientes = filter => {
    return RestClientObj.instanceAxios
      .post('/categoria/listaPendientes', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static listaSolicitudesCategoriaRevisados = filter => {
    return RestClientObj.instanceAxios
      .post('/categoria/listaRevisados', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static enviarSubsanacion = filter => {
    return RestClientObj.instanceAxios
      .post('/categoria/enviarSubsanacion', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static guardarSolicitud = filter => {
    return RestClientObj.instanceAxios
      .post('/categoria/guardarRevision/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static eliminarSolicitud = filter => {
    return RestClientObj.instanceAxios
      .post('/categoria/eliminarSolicitud/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static agregarSolicitud = filter => {
    return RestClientObj.instanceAxios
      .post('/categoria/add/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static listaTipoCertificado = () => {
    return RestClientObj.instanceAxios
      .get('/categoria/lista_certificado/')
      .then(response => response.data)
      .catch(error => error.response)
  }

  // TarifaCiudad
  static agregarTarifaCiudad = filter => {
    return RestClientObj.instanceAxios
      .post('/tarifa/tarifaCiudad/addTarifaciudad/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static agregarTarifaCiudadExcel = filter => {
    this.setAuthorization()
    return RestClientObj.instanceAxios
      .post('/tarifa/tarifaCiudad/addTarifaciudadExcel/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static consultarTarifaCiudad = filter => {
    return RestClientObj.instanceAxios
      .post('/tarifa/tarifaCiudad/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static eliminarTarifaCiudad = filter => {
    return RestClientObj.instanceAxios
      .post('/tarifa/tarifaCiudad/deleteTarifaciudad', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static actualizarTarifaCiudad = filter => {
    return RestClientObj.instanceAxios
      .post('/tarifa/tarifaCiudad/updateTarifaciudad/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  // TarifaClienteCiudad
  static agregarTarifaClienteCiudad = filter => {
    return RestClientObj.instanceAxios
      .post('/tarifa/tarifaClienteCiudad/addTarifaClienteciudad/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static agregarTarifaClienteCiudadExcel = filter => {
    this.setAuthorization()
    return RestClientObj.instanceAxios
      .post('/tarifa/tarifaClienteCiudad/addTarifaClienteciudadExcel/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static consultarTarifaClienteCiudad = filter => {
    return RestClientObj.instanceAxios
      .post('/tarifa/tarifaClienteCiudad/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static eliminarTarifaClienteCiudad = filter => {
    return RestClientObj.instanceAxios
      .post('/tarifa/tarifaClienteCiudad/deleteTarifaClienteciudad', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static actualizarTarifaClienteCiudad = filter => {
    return RestClientObj.instanceAxios
      .post('/tarifa/tarifaClienteCiudad/updateTarifaClienteciudad/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  // TarifaPlanta
  static agregarTarifaPlanta = filter => {
    return RestClientObj.instanceAxios
      .post('/tarifa/tarifaPlanta/addTarifaPlanta/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static consultarTarifaPlanta = filter => {
    return RestClientObj.instanceAxios
      .post('/tarifa/tarifaPlanta/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static eliminarTarifaPlanta = filter => {
    return RestClientObj.instanceAxios
      .post('/tarifa/tarifaPlanta/deleteTarifaPlanta', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static actualizarTarifaPlanta = filter => {
    return RestClientObj.instanceAxios
      .post('/tarifa/tarifaPlanta/updateTarifaPlanta/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  // servicioTransporte
  static consultarServicioTransporte = filter => {
    return RestClientObj.instanceAxios
      .post('/servicio/servicioTransporte/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static agregarServicioTransporte = filter => {
    return RestClientObj.instanceAxios
      .post('/servicio/servicioTransporte/addServicioTransporte/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static actualizarServicioTransporte = filter => {
    return RestClientObj.instanceAxios
      .post('/servicio/servicioTransporte/updateServicioTransporte/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static eliminarServicioTransporte = filter => {
    return RestClientObj.instanceAxios
      .post('/servicio/servicioTransporte/deleteServicioTransporte', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static getServicioTransporte = filter => {
    return RestClientObj.instanceAxios
      .post('/servicio/servicioTransporte/getServicioTransporte', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  // servicioTransporteCliente
  static consultarServicioTransporteCliente = filter => {
    return RestClientObj.instanceAxios
      .post('/servicio/servicioTransporteCliente/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static agregarServicioTransporteCliente = filter => {
    return RestClientObj.instanceAxios
      .post('/servicio/servicioTransporteCliente/addServicioTransporteCliente/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static eliminarServicioTransporteCliente = filter => {
    return RestClientObj.instanceAxios
      .post('/servicio/servicioTransporteCliente/deleteServicioTransporteCliente', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static actualizarServicioTransporteCliente = filter => {
    return RestClientObj.instanceAxios
      .post('/servicio/servicioTransporteCliente/updateServicioTransporteCliente/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }
  // credito
  static listadoCreditoUsuario = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/credito/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static agregarCreditoUsuario = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/credito/addCredito', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static actualizarCreditoUsuario = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/credito/updateCredito', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static agregarCreditoExcel = filter => {
    this.setAuthorization()
    return RestClientObj.instanceAxios
      .post('/admin/credito/addCreditoExcel/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static eliminarCredito = filter => {
    this.setAuthorization()
    return RestClientObj.instanceAxios
      .post('/admin/credito/deleteCredito/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }
  // rubros
  static listadoRubros = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/rubro/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static listadoTipoRubros = () => {
    return RestClientObj.instanceAxios
      .get('/admin/rubro/lista_tipo_rubro')
      .then(response => response.data)
      .catch(error => error.response)
  }

  static agregarRubro = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/rubro/addRubro', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static actualizarRubro = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/rubro/updateRubro', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static eliminarRubro = filter => {
    this.setAuthorization()
    return RestClientObj.instanceAxios
      .post('/admin/rubro/deleteRubro/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static getSolicitudReservas = () => {
    return RestClientObj.instanceAxios
      .get('/reservas/pendientes_confirmar')
      .then(response => response.data)
  }

  static reprogramarReserva = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/reprogramar_reserva', filter)
      .then(response => response.data)
  }

  static reprogramarHitos = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/reprogramar_hitos', filter)
      .then(response => response.data)
  }

  static guardarGuiaTransportista = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/actualizarGuiaTransportista', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static aceptarSolicitudReserva = reserva => {
    return RestClientObj.instanceAxios
      .post('/reservas/evaluar_solicitud', reserva)
      .then(response => response.data)
  }

  static actualizarChoferReserva = form => {
    return RestClientObj.instanceAxios
      .post('/reservas/actualizarChoferReserva', form)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static actualizarTransportistaReserva = form => {
    return RestClientObj.instanceAxios
      .post('/reservas/actualizarTransportistaReserva', form)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static actualizarTransportistaSeleccionados = form => {
    return RestClientObj.instanceAxios
      .post('/reservas/notificarTransportistasSeleccionados', form)
      .then(response => response.data)
  }

  static notificarReservaExportTransportistaFavorito = form => {
    return RestClientObj.instanceAxios
      .post('/reservas/notificarReservaTransportistaFavorito', form)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static notificarTransportistasFavoritos = form => {
    return RestClientObj.instanceAxios
      .post('/reservas/notificarTransportistasFavoritos', form)
      .then(response => response.data)
  }

  static listaContenedoresExportacion = form => {
    return RestClientObj.instanceAxios
      .post('/reservas/getContenedoresExportacion', form)
      .then(response => (response ? response.data : null))
  }

  static eliminarContenedorExportacion = form => {
    return RestClientObj.instanceAxios
      .post('/reservas/deleteContenedoresExportacion', form)
      .then(response => response.data)
  }

  static agregarContenedorExportacion = form => {
    return RestClientObj.instanceAxios
      .post('/reservas/addContenedoresExportacion', form)
      .then(response => response.data)
  }

  static consultarDocumentosImport = form => {
    return RestClientObj.instanceAxios
      .post('/dpw/cliente_contenedor', form)
      .then(response => response.data)
  }

  static consultarContenedorCita = form => {
    return RestClientObj.instanceAxios.post('/dpw/get_contenedor_cita', form).then(response => {
      const { data, status } = response
      return { ...data, status }
    })
  }

  static sincronizarEstadosDP = () => {
    return RestClientObj.instanceAxios
      .get('/dpw/sincronizar_estados_dp')
      .then(response => response.data)
  }

  static actualizarContenedor = (id_reserva, contenedor) => {
    return RestClientObj.instanceAxios
      .post('/reservas/update_contenedor', { id_reserva: id_reserva, contenedor: contenedor })
      .then(response => response.data)
  }

  static consultarDocumentosExport = form => {
    return RestClientObj.instanceAxios
      .post('/dpw/cliente_booking', form)
      .then(response => response.data)
  }

  static getMisReservas = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/misReservas', filter)
      .then(response => response.data)
  }

  static getMisReservasAdmin = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/misReservasAdmin', filter)
      .then(response => response.data)
  }

  static getMisReservasCliente = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/misReservasCliente', filter)
      .then(response => response.data)
  }

  static generarFacturaFOCAL = filter => {
    return RestClientObj.instanceAxios
      .post('/ordenes/facturar_orden', filter)
      .then(response => response.data)
  }

  static eliminarOrdenes = filter => {
    return RestClientObj.instanceAxios
      .post('/ordenes/eliminar_orden', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static generarDetalleGrupal = filter => {
    return RestClientObj.instanceAxios
      .post('/ordenes/getDetalleGrupal', filter)
      .then(response => response.data)
  }

  static generarOrdenPagoAdmin = filter => {
    return RestClientObj.instanceAxios
      .post('/ordenes/generarOrdenPagoAdmin', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static actualizarDatosOrden = filter => {
    return RestClientObj.instanceAxios
      .post('/ordenes/actualizarDatosOrden', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static buscarClientesOrdenes = filter => {
    return RestClientObj.instanceAxios
      .post('/ordenes/buscarClientesOrdenes', filter)
      .then(response => response.data)
  }

  static consultaDatosOrdenAdmin = filter => {
    return RestClientObj.instanceAxios
      .post('/ordenes/consultaDatosOrdenAdmin', filter)
      .then(response => response.data)
  }

  static getHitosReserva = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/get_hitos', filter)
      .then(response => response.data)
  }

  static getUbicacionesHitos = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/ubicacionesHitos', filter)
      .then(response => response.data)
  }

  static getUltimaUbicacionHito = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/getUltimaUbicacion', filter)
      .then(response => response.data)
  }

  static getUltimaUbicacionReservasEnCurso = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/ultimaUbicacionEnCurso', filter)
      .then(response => response.data)
  }

  static getTipo = () => {
    return RestClientObj.instanceAxios
      .get('/establecimiento/getTipo')
      .then(response => response.data)
  }

  static updateFirebaseToken = token => {
    return RestClientObj.instanceAxios
      .post('/users/update_firebase_token', token)
      .then(response => response.data)
  }
  /**
   * Consulta al servicio de DP las disponibilidades del día seleccionado
   * params:
   *    {
   *        desde: string - "yyyy-mm-dd HH:mm:ss"
   *        hasta: string - "yyyy-mm-dd HH:mm:ss"
   *    }
   */
  static consultarDisponibilidad = dateRange => {
    return RestClientObj.instanceAxios
      .post('/dpw/disponibilidad', dateRange)
      .then(response => response.data)
  }

  static consultarDashboardGantts = postData => {
    return RestClientObj.instanceAxios
      .post('/reservas/dashboard_gantts', postData)
      .then(response => response.data)
  }

  static misCamiones = postData => {
    return RestClientObj.instanceAxios
      .post('/camion/misCamiones', postData)
      .then(response => response.data)
  }

  static camionesTransportistaCita = filter => {
    return RestClientObj.instanceAxios
      .post('/camion/camionesTransportistaCita', filter)
      .then(response => response.data)
  }

  static conductoresTransportistaCita = filter => {
    return RestClientObj.instanceAxios
      .post('/chofer/conductoresTransportistaCita', filter)
      .then(response => response.data)
  }

  static actualizarCamionChofer = postData => {
    return RestClientObj.instanceAxios
      .post('/reservas/actualizar_camion_chofer', postData)
      .then(response => response.data)
  }

  // dashboard
  static estadoReservaCliente = postData => {
    return RestClientObj.instanceAxios
      .post('/dashboard/estadoReservasCliente', postData)
      .then(response => response.data)
  }

  static cantidadReservaCliente = postData => {
    return RestClientObj.instanceAxios
      .post('/dashboard/cantidadReservasCliente', postData)
      .then(response => response.data)
  }

  static estadoReservaFacturadasCliente = postData => {
    return RestClientObj.instanceAxios
      .post('/dashboard/estadoReservasFacturadasCliente', postData)
      .then(response => response.data)
  }

  static getDocumentosVacios = reserva => {
    return RestClientObj.instanceAxios
      .post('/dpw/get_documentos_vacios', reserva)
      .then(response => response.data)
  }
  static reservarVacioPorReserva = reserva => {
    return RestClientObj.instanceAxios
      .post('/reservas/reservar_vacio', reserva)
      .then(response => response.data)
  }
  static reservarVacioExport = reserva => {
    return RestClientObj.instanceAxios
      .post('/reservas/reservar_exp_vacio', reserva)
      .then(response => response.data)
  }

  static guardarReservasExportVacios = request => {
    return RestClientObj.instanceAxios
      .post('/reservas/crear_reservas_export_vacios', request)
      .then(response => response.data)
  }

  static reservaUpdateComentario = reserva => {
    return RestClientObj.instanceAxios
      .post('/reservas/update_comentario', reserva)
      .then(response => response.data)
  }
  static reservaGetComentario = reserva => {
    return RestClientObj.instanceAxios
      .post('/reservas/get_comentario', reserva)
      .then(response => response.data)
  }

  static lockDocumento = verify => {
    return RestClientObj.instanceAxios
      .post('/dpw/lock_documento', verify)
      .then(response => response.data)
  }
  static getIDBookingDP = booking => {
    return RestClientObj.instanceAxios
      .post('/dpw/cliente_booking_identificador', booking)
      .then(response => response.data)
  }

  static getIDContenedorDP = contenedor => {
    return RestClientObj.instanceAxios
      .post('/dpw/cliente_contenedor_identificador', contenedor)
      .then(response => response.data)
  }

  static getListaReglas = data => {
    return RestClientObj.instanceAxios.post('/reglas/', data).then(response => response.data)
  }

  static agregarRegla = data => {
    return RestClientObj.instanceAxios
      .post('/reglas/agregarRegla', data)
      .then(response => response.data)
  }

  static actualizarRegla = data => {
    return RestClientObj.instanceAxios
      .post('/reglas/actualizarRegla', data)
      .then(response => response.data)
  }

  static eliminarRegla = data => {
    return RestClientObj.instanceAxios
      .post('/reglas/eliminarRegla', data)
      .then(response => response.data)
  }

  static eliminarMatch = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/eliminarMatch', filter)
      .then(response => response.data)
  }

  static getDocumento = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/getDocumento', filter)
      .then(response => response.data)
  }

  static listaAgentesClientes = data => {
    return RestClientObj.instanceAxios.post('/agente/', data).then(response => response.data)
  }

  static buscarAgentes = data => {
    return RestClientObj.instanceAxios
      .post('/agente/buscarAgente', data)
      .then(response => response.data)
  }

  static registroAgente = data => {
    return RestClientObj.instanceAxios
      .post('/agente/registrarAgente', data)
      .then(response => response.data)
  }

  static get_bl = bl => {
    return RestClientObj.instanceAxios.post('/dpw/get_bl', bl).then(response => response.data)
  }

  static getDescripcionMercancia = () => {
    return RestClientObj.instanceAxios
      .get('/reservas/getDescripcionMercancia')
      .then(response => response.data)
      .catch(error => error.response)
  }

  static actualizarMercanciaReserva = request => {
    return RestClientObj.instanceAxios
      .post('/reservas/actualizarMercanciaReserva', request)
      .then(response => response.data)
  }

  static getCitasDPTransportistas = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/citasDPTransportistas', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static getSolicitudesMatch = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/getSolicitudesMatch', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static solicitudCrearMatchVacio = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/crearMatchVacio', filter)
      .then(response => response.data)
  }
}
