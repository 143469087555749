import React, { Component } from 'react'
import { Modal, Button, Form, Input, Icon, message } from 'antd'
import RestClientObj from '../../../../services/restClient'
import './style.less'

class index extends Component {
  state = {
    visible: false,
    loading: false,
    inputs: {
      actual: {
        visible: false,
        icon: 'eye',
      },
      nueva: {
        visible: false,
        icon: 'eye',
      },
      repetir_nueva: {
        visible: false,
        icon: 'eye',
      },
    },
  }

  componentDidMount() {
    const { ref2 } = this.props
    if (ref2) ref2(this)
  }

  open = () => {
    this.setState({ visible: true })
  }

  close = () => {
    this.setState({ visible: false }, () => {
      this.props.form.resetFields()
    })
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (err) return

      this.setState({ loading: true })
      RestClientObj.cambiarPassword(values)
        .then(resp => {
          message.success(resp.message)
          this.setState({ loading: false }, () => {
            this.close()
          })
        })
        .catch(error => {
          message.error(error.message)
          this.setState({ loading: false })
        })
    })
  }

  onClickVisible = ({ input }) => {
    const inputs = { ...this.state.inputs }
    inputs[input] = {
      visible: !inputs[input].visible,
      icon: inputs[input].visible ? 'eye' : 'eye-invisible',
    }

    this.setState({ inputs })
  }

  handleRepetirPassword = (rule, value, callback) => {
    const { getFieldValue } = this.props.form
    if (value && value !== getFieldValue('password_nueva')) {
      callback('Confirmación de Contraseña no coincide')
    }

    callback()
  }

  render() {
    const { form } = this.props
    const { visible, loading, inputs } = this.state
    const { getFieldDecorator } = form

    return (
      <Modal
        visible={visible}
        title="Cambiar Contraseña"
        closable
        style={{ top: 5 }}
        className="modal-cambiar-password"
        maskClosable={false}
        onCancel={() => this.close()}
        footer={[
          <Button
            key="key-guardar-cambios-contrasena"
            loading={loading}
            type="primary"
            htmlType="submit"
            onClick={this.handleSubmit}
          >
            Guardar
          </Button>,
          <Button key="key-cerrar-cambios-contrasena" onClick={() => this.close()}>
            Cerrar
          </Button>,
        ]}
      >
        <Form className="login-form-cambiar-password">
          <Form.Item>
            {getFieldDecorator('password_actual', {
              rules: [{ required: true, message: 'Ingresar Contraseña Actual' }],
            })(
              <Input
                placeholder="Contraseña Actual"
                type={inputs['actual'].visible ? undefined : 'password'}
                addonAfter={
                  <Icon
                    type={inputs['actual'].icon}
                    onClick={() => this.onClickVisible({ input: 'actual' })}
                  />
                }
              />,
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('password_nueva', {
              rules: [{ required: true, message: 'Ingresar Nueva Contraseña' }],
            })(
              <Input
                type={inputs['nueva'].visible ? undefined : 'password'}
                addonAfter={
                  <Icon
                    type={inputs['nueva'].icon}
                    onClick={() => this.onClickVisible({ input: 'nueva' })}
                  />
                }
                placeholder="Nueva Contraseña"
              />,
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('password_nueva_repetir', {
              rules: [
                { required: true, message: 'Confirmar Nueva Contraseña' },
                { validator: this.handleRepetirPassword },
              ],
            })(
              <Input
                type={inputs['repetir_nueva'].visible ? undefined : 'password'}
                addonAfter={
                  <Icon
                    type={inputs['repetir_nueva'].icon}
                    onClick={() => this.onClickVisible({ input: 'repetir_nueva' })}
                  />
                }
                placeholder="Confirmar Nueva Contraseña"
              />,
            )}
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

const WrappIndex = Form.create({ name: 'form-cambiar-password' })(index)
export default WrappIndex
