export async function getLeftMenuData() {
  return [
    {
      title: 'Dashboard',
      key: 'dashboardAlpha',
      url: '/dashboard',
      icon: 'icmn icmn-home',
    },
    {
      title: 'Solicitudes',
      key: 'solicitudReserva',
      url: '/reservas/solicitudes',
      icon: 'icmn icmn-alarm',
    },
    {
      title: 'Registrar Vacios',
      key: 'reservaVacio',
      url: '/reservas/create_vacio',
      icon: 'icmn icmn-history',
    },
    {
      title: 'Administrar Vacios',
      key: 'administrarVacio',
      url: '/reservas/adm_vacio',
      icon: 'icmn icmn-list2',
    },
    {
      title: 'Transporte',
      key: 'servicioTransporte',
      url: '/servicio/transporte',
      icon: 'icmn icmn-plus',
    },
    {
      title: 'Cliente/Ciudad',
      key: 'tarifaClienteCiudadqwe',
      url: '/tarifa/clienteCiudad',
      icon: 'icmn icmn-home',
    },
    {
      title: 'Planta',
      key: 'tarifaPlantaqwe',
      url: '/tarifa/planta',
      icon: 'icmn icmn-home',
    },
    {
      title: 'Transporte / Cliente',
      key: 'servicioTransporteCliente',
      url: '/servicio/transporteCliente',
      icon: 'icmn icmn-home',
    },
    {
      title: 'Pagos',
      key: 'ordenes_pago',
      url: '/ordenes/list',
      icon: 'icmn icmn-home',
    },
    {
      title: 'Mis Rubros',
      key: 'mis_rubros',
      url: '/rubros/list',
      icon: 'icmn icmn-list2',
    },
    {
      title: 'Tarifa ciudad',
      key: 'tarifaCiudadqwe',
      url: '/tarifa/ciudad',
      icon: 'icmn icmn-plus',
    },
    {
      title: 'Adm creditos clientes',
      key: 'adm_credito_cliente',
      url: '/creditos/list',
      icon: 'icmn icmn-user',
    },
    {
      title: 'Rubros-IVA',
      key: 'impuesto',
      icon: 'icmn icmn-price-tag',
      url: '/rubros/iva',
    },
    {
      title: 'Reservas',
      key: 'reservas',
      icon: 'icmn icmn-address-book',
      children: [
        {
          title: 'Consulta Documentos',
          key: 'consultaDocumentos',
          url: '/reservas/documentos/list',
          icon: 'icmn icmn-file-text2',
        },
        {
          title: 'Reservar Import',
          key: 'crearReservaImport',
          url: '/reservas/create_import',
          icon: 'icmn icmn-history',
        },
        {
          title: 'Reservar Export',
          key: 'crearReservaExport',
          url: '/reservas/create_export',
          icon: 'icmn icmn-history',
        },
        {
          title: 'Monitoreo',
          key: 'monitoreo',
          url: '/reservas/monitoreo',
          icon: 'icmn icmn-calendar',
        },
        {
          title: 'Mis Reservas',
          key: 'reservaList',
          url: '/reservas/list',
          icon: 'icmn icmn-calendar',
        },
      ],
    },
    {
      title: 'Establecimientos',
      key: 'establecimientos',
      icon: 'icmn icmn-office',
      children: [
        {
          title: 'Crear establecimiento',
          key: 'establecimientoCrear',
          url: '/establecimientos/crear',
          icon: 'icmn icmn-plus',
        },
        {
          title: 'Ver establecimiento',
          key: 'establecimientosList',
          url: '/establecimientos/list',
          icon: 'icmn icmn-home',
        },
        {
          title: 'Empresas',
          key: 'empresasList',
          url: '/establecimientos/empresa/',
          icon: 'icmn icmn-home',
        },
      ],
    },
    {
      title: 'Ruta',
      key: 'ruta',
      icon: 'icmn icmn-map',
      children: [
        {
          title: 'Crear ruta',
          key: 'crearRuta',
          url: '/ruta',
          icon: 'icmn icmn-plus',
        },
        {
          title: 'Ver Rutas',
          key: 'verRuta',
          url: '/ruta/list',
          icon: 'icmn icmn-eye',
        },
      ],
    },
    {
      title: 'Transportistas',
      key: 'pagesBlocks',
      icon: 'icmn icmn-road',
      url: '/transportistas/list',
    },
    {
      title: 'Agentes',
      key: 'agentes',
      icon: 'icmn icmn-road',
      url: '/agentes/',
    },
  ]
}

export async function getTopMenuData() {
  return [
    {
      title: 'Dashboard',
      key: 'dashboardAlpha',
      url: '/dashboard',
      icon: 'icmn icmn-home',
    },
    {
      title: 'Solicitudes',
      key: 'solicitudReserva',
      url: '/reservas/solicitudes',
      icon: 'icmn icmn-alarm',
    },
    {
      title: 'Registrar Vacios',
      key: 'reservaVacio',
      url: '/reservas/create_vacio',
      icon: 'icmn icmn-history',
    },
    {
      title: 'Administrar Vacios',
      key: 'administrarVacio',
      url: '/reservas/adm_vacio',
      icon: 'icmn icmn-list2',
    },
    {
      title: 'Transporte',
      key: 'servicioTransporte',
      url: '/servicio/transporte',
      icon: 'icmn icmn-plus',
    },
    {
      title: 'Cliente/Ciudad',
      key: 'tarifaClienteCiudadqwe',
      url: '/tarifa/clienteCiudad',
      icon: 'icmn icmn-home',
    },
    {
      title: 'Planta',
      key: 'tarifaPlantaqwe',
      url: '/tarifa/planta',
      icon: 'icmn icmn-home',
    },
    {
      title: 'Transporte / Cliente',
      key: 'servicioTransporteCliente',
      url: '/servicio/transporteCliente',
      icon: 'icmn icmn-home',
    },
    {
      title: 'Pagos',
      key: 'ordenes_pago',
      url: '/ordenes/list',
      icon: 'icmn icmn-home',
    },
    {
      title: 'Mis Rubros',
      key: 'mis_rubros',
      url: '/rubros/list',
      icon: 'icmn icmn-list2',
    },
    {
      title: 'Tarifa ciudad',
      key: 'tarifaCiudadqwe',
      url: '/tarifa/ciudad',
      icon: 'icmn icmn-plus',
    },
    {
      title: 'Adm creditos clientes',
      key: 'adm_credito_cliente',
      url: '/creditos/list',
      icon: 'icmn icmn-user',
    },
    {
      title: 'Rubros-IVA',
      key: 'impuesto',
      icon: 'icmn icmn-price-tag',
      url: '/rubros/iva',
    },
    {
      title: 'Reservas',
      key: 'reservas',
      icon: 'icmn icmn-address-book',
      children: [
        {
          title: 'Consulta Documentos',
          key: 'consultaDocumentos',
          url: '/reservas/documentos/list',
          icon: 'icmn icmn-file-text2',
        },
        {
          title: 'Reservar Import',
          key: 'crearReservaImport',
          url: '/reservas/create_import',
          icon: 'icmn icmn-history',
        },
        {
          title: 'Reservar Export',
          key: 'crearReservaExport',
          url: '/reservas/create_export',
          icon: 'icmn icmn-history',
        },
        {
          title: 'Monitoreo',
          key: 'monitoreo',
          url: '/reservas/monitoreo',
          icon: 'icmn icmn-calendar',
        },
        {
          title: 'Mis Reservas',
          key: 'reservaList',
          url: '/reservas/list',
          icon: 'icmn icmn-calendar',
        },
      ],
    },
    {
      title: 'Establecimientos',
      key: 'establecimientos',
      icon: 'icmn icmn-office',
      children: [
        {
          title: 'Crear establecimiento',
          key: 'establecimientoCrear',
          url: '/establecimientos/crear',
          icon: 'icmn icmn-plus',
        },
        {
          title: 'Ver establecimiento',
          key: 'establecimientosList',
          url: '/establecimientos/list',
          icon: 'icmn icmn-home',
        },
        {
          title: 'Empresas',
          key: 'empresasList',
          url: '/establecimientos/empresa/',
          icon: 'icmn icmn-home',
        },
      ],
    },
    {
      title: 'Ruta',
      key: 'ruta',
      icon: 'icmn icmn-map',
      children: [
        {
          title: 'Crear ruta',
          key: 'crearRuta',
          url: '/ruta',
          icon: 'icmn icmn-plus',
        },
        {
          title: 'Ver Rutas',
          key: 'verRuta',
          url: '/ruta/list',
          icon: 'icmn icmn-eye',
        },
      ],
    },
    {
      title: 'Transportistas',
      key: 'pagesBlocks',
      icon: 'icmn icmn-road',
      url: '/transportistas/list',
    },
    {
      title: 'Agentes',
      key: 'agentes',
      icon: 'icmn icmn-road',
      url: '/agentes/',
    },
  ]
}
